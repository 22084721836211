import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import FAQList from '../../general/faqsList';
import InnerPageHeader from "./components/innerPageHeader";
import { scrollToElement } from "../../../../utility/utils"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards";
import MoreForYouComponent from "../../general/more-for-you";

import EntryIllustration from '../../../../../assets/img/invoicing-hero-image.svg'
import EntryIllustrationBlur from '../../../../../assets/img/business/blur/kuda-invoicing-hero-blur.svg'
import DashboardIcon from "../../../../../assets/img/light-dashboard.inline.svg"
import InvoiceIcon from "../../../../../assets/img/kuda-invoice.inline.svg"
import SpendMoneyIcon from '../../../../../assets/img/kuda-money-dark.inline.svg'

// track due invoices images
import trackDueInvoiceImage from "../../../../../assets/img/business/invoicing/trackInvoicing-illustration.svg"
// entry content images
import ConvenientChannelsImage from "../../../../../assets/img/business/invoicing/convenientChannels-illustration.svg"
// entry content images
import RecieveInvoicingImage from "../../../../../assets/img/business/invoicing/recieveInvoicing-illustration.svg"

import KudaPOSTerminalIcon from "../../../../../assets/img/more-for-you/kuda-pos-icon.inline.svg"
import SoftPOSIcon from '.././../../../../assets/img/more-for-you/kuda-softPos-icon.inline.svg'
import VirtualPOSIcon from '../../../../../assets/img/more-for-you/kuda-virtual-icon.inline.svg'

import { Img } from "react-image";


const entryContent = {
  title: (<span>
    <span className="color-secondary">Automate</span>  your invoicing easily on the Kuda Business app.
  </span>),
  subtitle: "Kuda Business invoices are easy to create and send, and they give your customers different payment options so you can get paid faster.",
  name: "Open a Kuda Business Account",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
}

const businessTopFeatures = [
  {
    icon: <DashboardIcon />,
    text: "Create an estimate and convert it to an invoice in minutes."
  },
  {
    icon: <InvoiceIcon />,
    text: "Send invoices and schedule invoice reminders any time."
  },
  {
    icon: <SpendMoneyIcon />,
    text: "Give your customers a variety of easy-to-use options to pay you."
  },
]

const onboardingService = {
  title: "Keep track of due invoices and paid invoices easily wherever you are.",
  subtitle: "Take the stress out of receiving payments. Invoice notifications on the Kuda Business app keep you on top of your finances round the clock.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Open a Kuda Business Account",
  illustration: (
    <Img src={trackDueInvoiceImage} className="kuda-business-image" />

  ),
}

const ConvenientChannels = {
  title: "Create invoices and send them by email or other convenient channels.",
  subtitle: "Kuda Business simplifies your invoicing process forever with straightforward invoices that you can share with your customers almost anywhere.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Start using Kuda Business invoices now",
  illustration: (

    <Img src={ConvenientChannelsImage} className="kuda-business-image" />

  ),
}

const RecieveInvoicing = {
  title: "Receive invoice payments by transfer, card or Pay With Kuda.",
  subtitle: "There’s more than one way to get paid. Kuda Business invoices give your customers multiple payment options and no room for excuses.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Download Kuda Business",
  illustration: (

    <Img src={RecieveInvoicingImage} className="kuda-business-image" />

  ),
}


const questions = [
  {
    heading: "Why do you need invoicing?",
    list: (
      <span className="flex flex-column  mt-2">

        <span className="faq-description f-16">An invoice is a document for requesting payment for goods or services you’ve
          provided to a customer. it itemises the goods or services you’ve provided and how much you charged
          for them, and records the transaction with your customer.</span> <br />

        <span className="faq-description f-16">You need invoicing for the following reasons:</span><br />


        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. To request prompt payments from your customers.</div>
            <div className="mb-3">2. To keep legal records of your sales</div>
            <div className="mb-3"> 3. To track payments you receive for proper accounting</div>
            <div className="mb-3">4. To track your inventory if you sell physical products</div>
            <div className="mb-3">5. To help you report your income and pay the right amount of taxes.</div>
            <div className="mb-3">6. To give you information that can help you predict sales and create marketing strategies.</div>
          </div>
        </span>

      </span>),
    index: 1
  },
  {
    heading: "What’s the difference between an invoice and a receipt?",
    list: (
      <span className="flex flex-column  mt-2">
        <span className="faq-description f-16">An invoice and a receipt include similar information, but an invoice is used to request payment from a customer while a receipt is proof of a payment that a customer has made to you.</span>

      </span>),
    index: 2
  },
  {
    heading: "Who can send an invoice?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">Anyone, including freelancers, can send an
          invoice. What is important is that the invoice is accurate and includes all
          necessary information - the invoice number, the date the invoice is issued,
          contact details of the business or person issuing the business,
          the description of the goods or services provided and the terms of payment.
        </span>

      </div>),
    index: 3
  },
  {
    heading: "Is an invoice a recieveable?",
    list: (
      <div className="flex flex-column  mt-2">
        <span className="faq-description f-16">
          Yes, an invoice is a receivable because it documents money that you’re owed by a customer. Once an invoice is paid, it is no longer a receivable.
        </span>

      </div>),
    index: 4
  },
  {
    heading: "Is an invoice debited or credited?",
    list: (
      <div className="flex flex-column  mt-2">
        <div className="faq-description f-16">
          Since an invoice documents money that is paid to you by a customer, it is credited to your account and debited to the customer’s account.
        </div>

      </div>),
    index: 5
  },
  {
    heading: "What are the benefits of electronic invoicing?",
    list: (
      <div className="flex flex-column  mt-2">
        <div className="faq-description f-16"> Electronic invoicing, or using invoicing software instead of manual invoicing methods, offers the following advantages:
        </div>

        <span className="pt-3 career-message-margin-bottom ">
          <div className="flex flex-column  leading-md-6 f-md-16">
            <div className="mb-3">1. It makes it easy for you to automate your invoicing process and integrate it easily into your business accounting system.</div>
            <div className="mb-3">2. It saves you the cost of printing paper invoices, sending them by courier, and storing them.</div>
            <div className="mb-3"> 3. It saves you the time you would have spent on manual invoicing, freeing up space on your schedule to do more meaningful work.</div>
            <div className="mb-3">4. Because it is automated, electronic invoicing reduces the likelihood of human error and increases accounting efficiency.</div>
            <div className="mb-3">5. It reduces the time it takes for you to issue invoices and receive payments</div>
            <div className="mb-3">6. It makes it possible for you to store your invoices securely online and guarantee their authenticity with electronic signatures.</div>
          </div>
        </span>

      </div>),
    index: 6
  },
]

const moreForYou = [
  {
    icon: <KudaPOSTerminalIcon />,
    title: "POS",
    subText: `Buy a physical POS, lease it for a 
    one-time fee, or get a virtual POS to accept payments quickly.`,
    linkTo: `/en-ng/business/pos/`
  },

  {
    icon: <SoftPOSIcon />,
    title: "softPOS",
    subText: `Turn your smartphone into a POS and give your customers more ways to pay you.`,
    linkTo: `/en-ng/business/softpos-payments/`
  },
  {
    icon: <VirtualPOSIcon />,
    title: "Virtual POS",
    subText: `Empower your salespeople to accept payments instantly at all your business locations.`,
    linkTo: `/en-ng/business/virtual-pos/`
  },

]

const BusinessInvoicing = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (

    <Fragment>

      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<Img src={EntryIllustrationBlur} className="hero-illustration"/>}
      />
      <DarkBelowFoldCards topFeatures={businessTopFeatures} />
      <ImageDarkTextRightWithButton
        title={onboardingService.title}
        subtitle={onboardingService.subtitle}
        illustration={onboardingService.illustration}
        name={onboardingService.name}
        url={onboardingService.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={ConvenientChannels.title}
        subtitle={ConvenientChannels.subtitle}
        illustration={ConvenientChannels.illustration}
        name={ConvenientChannels.name}
        url={ConvenientChannels.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={RecieveInvoicing.title}
        subtitle={RecieveInvoicing.subtitle}
        illustration={RecieveInvoicing.illustration}
        name={RecieveInvoicing.name}
        url={RecieveInvoicing.url}
        isExternal={true}
      />

      {/* FAQ for invoice here */}
      <FAQList title={"Invoicing FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou}/>
      <CTABusiness />
    </Fragment>
  )
}

export default BusinessInvoicing

